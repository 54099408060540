<template>
  <div class="find_doc">
    <div class="header">
      <van-search v-model="searchValue" right-icon left-icon="" @search="onSearch" placeholder="输入症状、疾病、医院、科室、医生名">
        <div slot="right-icon" class="search_img" @click="onSearch">
          <img src="../assets/img/seach_icon.png" alt="">
        </div>
      </van-search>
      <p>*若你不清楚您的问题属于哪个科室，可以直接输入症状查询</p>
    </div>
    <div class="content">
      <div class="col" v-for="item in getClinicList" :key="item.clinic_no">
        <div class="item" @click="toDocList(item.clinic_no, item.clinic_name)">
          <div class="icon">
            <img :src="filterImg(item.clinic_no)" :class="filterClass(item.clinic_no)" alt="">
          </div>
          <p>{{item.clinic_name}}</p>
        </div>
      </div>
    </div>
    <v-login :is_login="is_login" @onLoginSuccess="onLoginSuccess" />
  </div>
</template>
<script>
import vLogin from "@/components/login";
import department from '../utils/department';
import BASE_URL from "@/request/base";
import { getQueryVariable } from "../utils/util";
export default {
  components: { vLogin },
  data() {
    return {
      searchValue: '',
      is_login: false,
      getClinicList: department.getClinicList,
      url: require('../assets/img/icon_5.png')
    }
  },
  created() {
    let loginData = JSON.parse(localStorage.getItem("userData")) || "";
    if (loginData) {
      this.is_login = false;
      this.heightPrice();
    } else {
      this.is_login = true;
    }
  },
  mounted() {
    if (navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
      wx.miniProgram.getEnv((res)=>{
        if (res.miniprogram) {        //在小程序
        } else {                   //在公众号
          var wxUrl = `${location.href}`;
          let openId = localStorage.getItem("openid") || "";
          if (!openId) {
            if (
              navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
              "micromessenger"
            ) {
              if (wxUrl.indexOf("code") > -1) {
                let code = getQueryVariable("code");
                this.$http(
                  "get",
                  "/server/pay/getOfficialAccountOpenid?code=" + code,
                  {},
                  1
                ).then((res) => {
                  if (res.data.code == 200) {
                    this.openid = res.data.data;
                    localStorage.setItem("openid", this.openid);
                  }
                });
              } else {
                const appid = "wx41c11ad329b19f43";
                let http =
                  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                  appid +
                  "&redirect_uri=" +
                  wxUrl +
                  "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
                location.href = http;
              }
            }
          }
        }
      })
    }
  },
  methods: {
    heightPrice() {
      this.$store.dispatch('setPrice');
    },
    // 搜索
    onSearch() {
      let data = {
        query_text: this.searchValue,
        type: 1
      }
      this.$router.push({ path: '/doctor_list', query: data });
    },
    // 根据科室找医生
    toDocList(clinic_no, text) {
      let data = {
        clinic_no: clinic_no,
        type: 2,
        query_text: text
      }
      this.$router.push({ path: '/doctor_list', query: data });
    },
    filterImg(id) {
      return {
        '1': require('../assets/img/icon_5.png'),
        '2': require('../assets/img/icon_3.png'),
        '3': require('../assets/img/icon_9.png'),
        '4': require('../assets/img/icon_10.png'),
        '6': require('../assets/img/icon_13.png'),
        '7': require('../assets/img/icon_6.png'),
        '8': require('../assets/img/icon_17.png'),
        '9': require('../assets/img/icon_11.png'),
        '11': require('../assets/img/icon_16.png'),
        '12': require('../assets/img/icon_15.png'),
        '13': require('../assets/img/icon_8.png'),
        '14': require('../assets/img/icon_4.png'),
        '15': require('../assets/img/icon_12.png'),
        '16': require('../assets/img/icon_14.png'),
        '17': require('../assets/img/icon_7.png'),
        '21': require('../assets/img/icon_2.png'),
        '22': require('../assets/img/icon_1.png'),
      }[id + ''];
    },
    filterClass(id) {
      return {
        '15': 'icon1',
        '14': 'icon2'
      }[id + ''];
    },
    // 登录成功
    onLoginSuccess() {
      this.is_login = false;
      this.heightPrice();
    }
  }
}

</script>
<style lang="less" scoped>
.search_img {
  width: 0.36rem;
  height: 0.38rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.find_doc {
  background: #f8f8f8;
  min-height: 100%;
  .header {
    background: #fff;
    text-align: center;
    padding-bottom: 0.2rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    p {
      font-size: 0.22rem;
      color: #999999;
    }
  }
  .content {
    padding-top: 1.62rem;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    // justify-content: space-around;
    width: 100%;
    .col {
      width: 33%;
      padding: 8px;

      // padding: 15rpx;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;

      .icon {
        margin-bottom: 0.16rem;
        text-align: center;
        // width: 0.6rem;
        // height: 0.6rem;
        img {
          width: 0.6rem;
          height: 0.6rem;
          //   text-align: center;
        }
        .icon1 {
          width: 0.72rem;
          height: 0.44rem;
        }
        .icon2 {
          width: 0.46rem;
          height: 0.64rem;
        }
      }
      p {
        font-size: 0.24rem;
        color: #666666;
      }
    }
    .item {
      border-radius: 0.16rem;
      //   background: #fff;
      text-align: center;
      padding: 0.24rem 0;
      text-align: center;
      background: #fff;
    }
  }
}
.find_doc /deep/ .van-search {
  //   height: 0.62rem;
  border-radius: 8px;
}
</style>
